.navbar_style {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #DBDBDB;
  min-height: 10vh !important;

  width: 100%;
}
.active {
  font-family: Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  color: red;
  text-transform: uppercase;
}
.header_items {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8%;
  flex-wrap: wrap;
  row-gap: 15px;
  list-style: none;
  padding-top: 0px;
  padding-left: 0px;
  margin: 0;
  width: 100%;
  color: black;
}
.header_item {
  font-size: 13px;
  font-weight: 400;
  line-height: 80px;
  min-width: 100px;
  text-align: center;
}
.header_item .header_link {
  font-family: Helvetica, sans-serif;
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  text-decoration: none;
  color: #000;
  text-transform: uppercase;
}
.header_item .header_link:hover {
  color:  #e75639;
}
.header_link.active {
  color: #e75639;
}
.heading_box {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #8b0000;
  width: 100%;
  min-height: 15vh;
  padding: 20px;
  min-width: 70%;
}
.heading {
  font-family: Helvetica, sans-serif;
  font-size: 40px;
  line-height: normal;
  font-weight: 700;
  color: #fff;
  letter-spacing: 4px;
  text-transform: uppercase;
  padding: 0px 20px;
}
.hamburger_icon {
  display: none;
  color: black;
}
@media screen and (max-width: 576px) {
  .hamburger_icon {
    display: block;
    color: black;
    align-self: flex-start;
    padding: 20px 20px 20px 20px;
    font-size: 20px;
  }
  .hamburger_icon:hover {
    color: #e75639;
  }
  .header_items {
    margin-top: 0px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 15px;
    padding-bottom: 20px;
  }
  .heading {
    font-size: 20px;
    letter-spacing: 3px;
    line-height: normal;
  }
}
