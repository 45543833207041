.container_back {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  /* background: linear-gradient(180deg, #73AFE1, #8D75C3); */
  /* opacity: 1; */

}


.form_container {
  width: 80%;
  margin: 80 auto;
  padding: 50px 50px ;
  margin: 60px 0px !important;
  border-radius: 5px;
  row-gap: 10px !important;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);


}

.form_main {
  width: 100%;
}

.padding_box {
  padding: 0px 12px 0px 12px;
}
.myCustomSelect {
  height: 45px;
}
.form-check-label span {
  margin-right: 20px; /* Adjust the value to control the spacing */
}

.errors {
  color: red;
  margin-bottom: 0px;
  padding: 5px 0px 0px 0px;
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
}

.input_radio {
  background-color: #D9D9D9 !important;
}

.input_radio[type='radio']:checked {
  /* box-shadow: none !important; */
  background: #F1A41D !important;
  border: none;
}

.input_textarea {
  height: 140px;
  resize: none;
}

.main_radio {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.radio_space {
  display: flex;
  align-items: center;
  gap: 10px;
}

.input_radio {
  background-color: #d9d9d9;
}

.form-group {
  margin-bottom: 15px;
}

label {

  margin: 10px 0px 5px 0px;
  font-weight: bold;
  color: #161a19;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea,
select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.btn_main {
  /* border: 3px solid green; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_main1 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0px 20px auto;
  width: 35%;

}

.btn_main2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px auto 20px 0px;

  width: 35%;
}

.btn_cancel {

  background-color: #8b0000;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn_cancel:hover {
  background-color: #0056b3;

}

.btn_submit {

  background-color: #f1a41d;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin-bottom: 20px !important;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

}
.btn_submit_Disable {

  background-color:gray;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: not-allowed;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.btn_submit:hover {
  background-color: #0056b3;

}
.message_show{
  width: 99%;
  min-height: 84px;
  border-radius: 5px;
  margin: 30px 0px 0px 0px !important;
  background-color: #198754;
  display: flex;
  flex-wrap: wrap;
  align-items: center; /* Center vertically */

}
.message_show_text{

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left; 
  color: #ffffff;
}

.close_icon{
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}




@media screen and (max-width:990px) {

  .btn_main1 {

    width: 150px;

  }

  .btn_main2 {

    width: 150px;
  }

  .btn_cancel {

    background-color: #8b0000;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn_cancel:hover {
    background-color: #0056b3;

  }

  .btn_submit {

    background-color: #f1a41d;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .btn_submit:hover {
    background-color: #0056b3;

  }

}
@media screen and (max-width:610px) {
  .form_container {
    width: 96%;
    padding: 50px 15px;
  }

  .btn_main1 {
    width: 120px;
    font-size: 12px;

  }

  .btn_main2 {
    width: 120px;
    font-size: 12px;
  }
}