.img_main {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.container_main {
  width: 100%;
  height: auto;
}
.portal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  overflow: auto;
  left: 0;
}
.opport_box {
  color: #8b0000;
  font-family: "Anton", sans-serif;
  line-height: normal;
  font-size: 56px;
  justify-content: center;
  display: flex;
  align-items: center;
  border: 2px solid red;
}
.opportunities_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  text-align: center;
  padding: 40px 20px;
}

.oppor_span {
  display: block;
  font-weight: bold;
  font-family: Helvetica, sans-serif;
  font-size: 18px;
  width: 60%;
  line-height: 30px;
  color: #000000;
}
.oppor_heading {
  color: #8b0000;
  font-family: "Anton", sans-serif;
  line-height: normal;
  letter-spacing: 3px;
  font-size: 56px;
  font-weight: 800;
}
.career_box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center !important;
  min-height: 391px;
  background-color: #dbdbdb;
  padding: 40px;
}
.heading {
  color: #8b0000;
  font-family: "Anton", sans-serif;
  line-height: normal;
  font-size: 56px;
  font-weight: 800;
  letter-spacing: 3px;
  padding-right: 40px;
}
.ocmt_tex {
  font-family: Helvetica, sans-serif;
  line-height: 30px;
  font-size: 18px;
  color: #000000;
  font-style: bold;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
}

.container_boxes {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
  width: 90%;
  margin: 40px auto !important;
}
.mutlti_img {
  width: 100%;
  height: 100%;
}
.container_boxes_text {
  font-family: Helvetica, sans-serif;
  line-height: normal;
  font-size: 17px;
  font-weight: 700;
  color: #8b0000;
}
.img_oppor {
  width: 100%;
  min-height: 500px;
  object-fit: cover;
}
.click_details {
  width: 100%;
  height: 100%;
  padding: 40px 30px 40px 50px;
  background-color: #dbdbdb;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.click_main {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  width: 100%;
  padding: 10px 0px;
  column-gap: 20px;
}
.click_img {
  width: 30px;
  height: 24px;
}
.click_span {
  color: #000000;
  font-family: Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
}
.parent_box_full {
  width: 100%;
  min-height: 600px;
  position: relative;
  background-color: #ffffff;
}
.bottom_box {
  position: absolute;
  bottom: 0px;
  left: 60%;
  transform: translateX(-50%);
  width: 150%;
  min-height: 500px;
  background-color: #8b0000;
  border-top-left-radius: 120%;
  box-shadow: -15px -15px 0px 4px #9f3636, -30px -30px 0px 8px #b36d6d,
    -45px -45px 0px 12px #c7a4a4;
}

.download_details {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically center the content */
  align-items: center; /* Horizontally center the content */
  min-height: 500px;
  width: 85%;
  margin: 0 auto;
  position: relative;
  left: 30%;
  transform: translateX(-40%);
  padding: 180px 0px 0px 0px;
}
.brochure {
  color: #fff;
  font-family: "Anton", sans-serif;
  line-height: normal;
  font-size: 56px;
  font-weight: 700;
  width: 80%;
  display: flex;
  justify-content: center;
  letter-spacing: 3px;
  margin-bottom: 30px;
  margin-left: -5%;
  padding: 0px 40px;
}

.brochure_text {
  font-family: Helvetica, sans-serif;
  font-size: 16px;
  line-height: 30px;
  font-weight: bold;
  color: #fff;
  margin-left: -5%;
  width: 80%;
  text-align: center;
  padding: 0px 40px;
}

.brochure_button {
  width: 142px;
  height: 47px;
  font-family: Helvetica, sans-serif;
  font-size: 16px;
  background-color: #f1a41d;
  border-radius: 12px;
  color: #ffff;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  transition: all 0.4s linear;
  text-align: center;
}

.brochure_button:hover {
  background-color: white;
  color: red;
  border: 1px solid red;
}

.Store_container {
  width: 100%;
  background-color: #fff;
  padding: 60px 40px 40px 40px;
}
.store_boxes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  gap: 20px;
  width: 90%;
  margin: 40px auto;
}
.store_box_item {
  width: auto;
  height: 460px;
  border-radius: 15px;
  border: 1px solid #DBDBDB;
}
.store_box_image {
  height: 75%;
  width: 100%;
  border-radius: 15px 15px 0px 0px;
  overflow: hidden;
}
.store_box_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px 15px 0px 0px;
  transform: scale(1);
  transition: transform 0.4s linear;
}
.store_box_image img:hover {
  transform: scale(1.1);
}
.store_box_title {
  height: 25%;
  width: 100%;
  background-color: #DBDBDB;
  border-radius: 0px 0px 15px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.store_boxtext {
  font-family: Helvetica, sans-serif;
  padding: 0px 10px;
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
  color: #8b0000;
  text-align: center;
}

@media screen and (max-width: 900px) {
  .opportunities_text {
    padding: 40px 20px;
  }
  .oppor_span {
    width: 80%;
  }
  .oppor_heading {
    font-size: 45px;
  }
  .heading {
    font-size: 45px;
    padding-right: 30px;
    text-align: center;
  }
  .ocmt_tex {
    font-size: 16px;
    padding-left: 20px;
    row-gap: 25px;
    text-align: justify;
  }
  .click_details {
    padding: 40px 30px 40px 30px;
  }
  .brochure {
    font-size: 45px;
    font-weight: 700;
    width: 80%;
    margin-bottom: 20px;
    margin-left: -5%;
    padding: 0px 40px;
    text-align: center;
  }
  .store_boxes {
    width: 100%;
  }
  .store_boxtext {
    font-size: 16px;
    line-height: 24px;
    padding: 0 5px;
  }
  .Store_container {
    padding: 60px 40px 40px 20px;
  }
}
@media screen and (max-width: 768px) {
  .img_oppor {
    width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
}
@media screen and (max-width: 576px) {
  .opportunities_text {
    padding: 40px 20px;
  }
  .oppor_span {
    width: 96%;
  }
  .oppor_heading {
    font-size: 40px;
    padding: 0px 20px;
  }
  .heading {
    font-size: 40px;
    padding-right: 30px;
    text-align: center;
    padding-bottom: 20px;
  }
  .ocmt_tex {
    font-size: 15px;
    padding-left: 20px;
    row-gap: 25px;
    text-align: justify;
  }
  .click_details {
    padding: 40px 20px 40px 20px;
  }
  .brochure {
    font-size: 35px;
    font-weight: 700;
    width: 80%;
    margin-bottom: 20px;
    margin-left: -5%;
    padding: 0px 30px;
    text-align: center;
  }
  .store_boxes {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
  .Store_container {
    padding: 0px 20px;
  }
 
  .store_box_item {
    height: 330px;
  }
}
/* @media screen and (max-width:1024px) {
  .img_main {
    height: 350px !important;
  }
  .opport_box {
    font-size: 39px;
  } 
  .oppor_span {
    font-size: 13px;
    margin-bottom: 21px;
  }
  .oppor_heading {
    font-size: 39px;
  }
  .career_box {
    min-height: 274px;
    padding: 40px;
  }
  .heading {
    font-size: 39px;
    margin: 35px 0px 0px 63px;
  }
  .ocmt_tex {
    line-height: 21px;
    font-size: 13px;
    margin-top: 49px !important;
  }
  .ocmt_two_para{
    padding-top: 21px ;
  }
  .container_boxes_text {
    font-size: 12px;
  }
  .img_oppor {
    height: 385px;
  }
  .click_details {
    height: 385px;
  }
  .click_main {
    padding: 14px 0px 14px 63px;
    column-gap: 14px;
  }
  .click_img {
    width: 21px;
    height: 17px;
  }
  .click_span {
    font-size: 13px;
  }
  .parent_box_full {
    min-height: 420px;
  }
  .bottom_box {
    left: 42%;
    transform: translateX(-35%);
    
    width: 150%;
    min-height: 350px;
    border-top-left-radius: 84%;
    box-shadow: -11px -11px 0px 4px #9f3636, -21px -21px 0px 6px #b36d6d,
      -32px -32px 0px 8px #c7a4a4;
  }
  
  .download_details {
    height: 350px;
    width: 85%;
    position: relative;
    padding: 126px 0px 0px 0px;
  }
  .brochure {
    font-size: 39px;
    margin-bottom: 21px;
  }
  
  .brochure_text {
    font-size: 11px;
  }
  
  .brochure_button {
    width: 99px;
    height: 33px ;
    font-size: 11px;
   border-radius: 8px;
    margin: 14px auto;
   
  }
  
}
@media screen and (max-width:900px) {
  .img_main {
    height: 350px !important;
  }
  .opport_box {
    font-size: 39px;
  } 
  .oppor_span {
    font-size: 13px;
    margin-bottom: 21px;
  }
  .oppor_heading {
    font-size: 39px;
  }
  .heading {
    font-size: 39px;
    margin: 35px 0px 0px 63px;
  }
  .ocmt_tex {
    line-height: 21px;
    font-size: 13px;
    margin-top: 49px !important;
  }
  .ocmt_two_para{
    padding-top: 21px ;
  }
  .container_boxes_text {
    font-size: 12px;
  }
  .img_oppor {
    height: 385px;
  }
  .click_details {
    height: 385px;
  }
  .click_main {
    padding: 14px 0px 14px 63px;
    column-gap: 14px;
  }
  .click_img {
    width: 21px;
    height: 17px;
  }
  .click_span {
    font-size: 12px;
  }
  .parent_box_full {
    min-height: 420px;
  }
  .bottom_box {
    left: 42%;
    transform: translateX(-35%);
    
    width: 150%;
    min-height: 350px;
    border-top-left-radius: 84%;
    box-shadow: -11px -11px 0px 4px #9f3636, -21px -21px 0px 6px #b36d6d,
      -32px -32px 0px 8px #c7a4a4;
  }
  
  .download_details {
    height: 350px;
    width: 85%;
    position: relative;
    padding: 126px 0px 0px 0px;
  }
  .brochure {
    font-size: 39px;
    margin-bottom: 21px;
  }
  
  .brochure_text {
    font-size: 11px;
  }
  
  .brochure_button {
    width: 99px;
    height: 33px ;
    font-size: 11px;
   border-radius: 8px;
    margin: 14px auto;
   
  }
  
}
@media screen and (max-width:768px) {
  .img_main {
    height: 350px !important;
  }
  .opport_box {
    font-size: 39px;
  } 
  .oppor_span {
    font-size: 13px;
    margin-bottom: 21px;
  }
  .oppor_heading {
    font-size: 39px;
  }
  .heading {
    font-size: 39px;
    margin: 35px 0px 0px 63px;
  }
  .ocmt_tex {
    line-height: 21px;
    font-size: 13px;
    margin-top: 49px !important;
  }
  .ocmt_two_para{
    padding-top: 21px ;
  }
  .container_boxes_text {
    font-size: 12px;
  }
  .img_oppor {
    height: 385px;
  }
  .click_details {
    height: 385px;
  }
  .click_main {
    padding: 14px 0px 10px 30px;
    column-gap: 14px;
  }
  .click_img {
    width: 21px;
    height: 17px;
  }
  .click_span {
    font-size: 13px;
  }
  .parent_box_full {
    min-height: 420px;
  }
  .bottom_box {
    left: 42%;
    transform: translateX(-35%);
   
    width: 150%;
    min-height: 350px;
    border-top-left-radius: 84%;
    box-shadow: -11px -11px 0px 4px #9f3636, -21px -21px 0px 6px #b36d6d,
      -32px -32px 0px 8px #c7a4a4;
  }
  
  .download_details {
    height: 350px;
    width: 85%;
    position: relative;
    padding: 126px 0px 0px 0px;
  }
  .brochure {
    font-size: 39px;
    margin-bottom: 21px;
  }
  
  .brochure_text {
    font-size: 11px;
  }
  
  .brochure_button {
    width: 99px;
    height: 33px ;
    font-size: 11px;
   border-radius: 8px;
    margin: 14px auto;
   
  }
  
}
 @media screen and (max-width:600px) {
  .heading {
    font-size: 39px;
    margin: 35px 0px 0px 63px;
  }
  .ocmt_tex {
    line-height: 21px;
    font-size: 13px;
    margin-top: 49px !important;
  }
  .ocmt_two_para{
    padding-top: 21px ;
  }
  .brochure {
    font-size: 30px;
    margin-bottom: 21px;
  }
 }
 @media screen and (max-width:600px) {
  .brochure {
    font-size: 30px;
    margin-bottom: 21px;
  }
 } */
