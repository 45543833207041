@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&display=swap");

.opportunity_blocks {
  width: 100%;
  min-height: 400px;
  background-color: #8b0000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 20px;
}
.blocks_heading {
  font-family: "Anton", sans-serif;
  font-size: 56px;
  line-height: normal;
  font-weight: 900;
  letter-spacing: 5.6px;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
}
.paragraphs_text {
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  font-family: Helvetica, sans-serif;
  width: 60%;
  margin: 0 auto;
  text-align: justify;
  padding-top: 25px;
  color: #fff;
}
.Stem2_program {
  width: 100%;
  min-height: auto;
  overflow: hidden;
}
.Stem_program_box {
  height: 100%;
  width: 100%;
  padding: 60px 0px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 50px;
}
.Stem_heading {
  font-family: "Anton", sans-serif;
  font-size: 38px;
  line-height: normal;
  font-weight: 500;
  letter-spacing: normal;
  text-transform: capitalize;
  color: #8b0000;
  text-align: center;
}
.Stem_text {
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  font-family: Helvetica, sans-serif;
  width: 90%;
  margin: 0 auto;
  text-align: center;
  color: #000;
  text-align: justify;
}
.Stem_text1 {
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  font-family: Helvetica, sans-serif;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  color: #000;
  text-align: justify;
}

.stemImages_box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.new_box {
  height: 85%;
  width: 85%;
}
.stemImages_box img {
  width: 100%;
  height: 100% !important;
}
.content_box {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.Stem_Link_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 3px;
  width: 100%;
}
.stem_link_title {
  font-family: Helvetica, sans-serif;
  color: #8b0000;
  font-size: 18px;
  text-transform: capitalize;
  line-height: normal;
  font-weight: bolder;
  text-align: center;
}
.stem_link_text {
  font-family: Helvetica, sans-serif;
  color: #8b0000;
  font-size: 16px;
  text-transform: capitalize;
  line-height: normal;
  font-weight: 400px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .paragraphs_text {
    width: 90%;
  }
}
@media screen and (max-width: 768px) {
  .blocks_heading{
    font-size: 35px;
  }
  .paragraphs_text {
    width: 95%;
    font-size: 18px;
    padding: 0px;
  }
}
@media screen and (max-width: 576px) {
  .Stem_program_box {
    height: fit-content;
    padding: 0px 0px 25px 0px;
  }
}
