.mfooter {
  background: #dbdbdb;
  padding: 40px 0px;
  min-height: 150px;
  width: 100%;
  text-align: center;
  color: #8b0000;
  display: flex;
  align-items: center;
  row-gap: 25px;
  justify-content: center;
}
.Socail_icons {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 15px;
  row-gap: 10px;
}
.Socail_icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #8b0000;
  border-radius: 50%;
  transform: scale(1);
  transition: all 0.4s linear;
  color: #fff;
  font-size: 20px;
}
.Socail_icon:hover {
  transform: scale(1.1);
  color: #13336e;
  background-color: #f1a41d;
}
.stem_second_text {
  font-size: 20px;
  font-weight: 400px;
  color: #8b0000;
  line-height: 28px;
  text-align: center;
}
/* header */
.active {
  font-family: Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-decoration: 1px underline #e75639;
  color: red;
  text-transform: uppercase;
}
.header_items {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5%;
  row-gap: 10px;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0px;
  width: 100%;
  /* border: 4px solid red;    */
}
.header_item {
  font-size: 13px;
  font-weight: 400;
  min-width: 140px;

}
.header_item .header_link {
  font-family: Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-decoration: 1px underline #8b0000;
  color: #8b0000;
  text-transform: uppercase;
  
}
.header_item .header_link:hover {
  color: #e75639;
  text-decoration: 1px underline #e75639;
}
.header_link.active {
  color: #e75639;
  text-decoration: 1px underline #e75639;
}
.heading {
  font-family: Helvetica, sans-serif;
  font-size: 40px;
  line-height: normal;
  font-weight: 700;
  color: #fff;
  letter-spacing: 4px;
  text-transform: uppercase;
}
@media screen and (max-width: 768px) {
  .mfooter {
    padding: 30px 30px;
    row-gap: 20px;
  }
  .Socail_icons {
    column-gap: 15px;
    row-gap: 10px;
  }
  .Socail_icon {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
  .stem_second_text {
    font-size: 20px;
  }
  /* header */
  .active {
    font-family: Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-decoration: 1px underline #e75639;
    color: red;
    text-transform: uppercase;
  }
  .header_items {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8%;
    row-gap: 10px;
    flex-wrap: wrap;
    list-style: none;
    padding-left: 0px;
    width: 100%;
  }
  .header_item {
    font-size: 14px;
  }
  .header_item .header_link {
    font-size: 14px;
  }
}
@media screen and (max-width: 576px) {
  .mfooter {
    padding: 30px 30px;
    row-gap: 20px;
  }
  .Socail_icons {
    column-gap: 12px;
    row-gap: 10px;
  }
  .Socail_icon {
    width: 35px;
    height: 35px;
    font-size: 17px;
  }
  .stem_second_text {
    font-size: 17px;
  }
  /* header */
  .active {
    font-family: Helvetica, sans-serif;
    font-size: 12px;
    font-weight: 400;
    text-decoration: 1px underline #e75639;
    color: red;
    text-transform: uppercase;
  }
  .header_items {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8%;
    row-gap: 10px;
    flex-wrap: wrap;
    list-style: none;
    padding-left: 0px;
    width: 100%;
  }
  .header_item {
    font-size: 12px;
  }
  .header_item .header_link {
    font-size: 12px;
  }
}
