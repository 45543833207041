@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&display=swap");

.home_container {
  min-height: 90vh;
  width: 100%;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}
.model_overlay{
  background: linear-gradient(rgba(127, 127, 127,.5) 0%, rgba(127, 127, 127,.5) 100%) !important;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
}
.model_body{
  margin-top: 10px;
  text-align: justify;
  min-height: 120px !important;
}
.model_style{
  min-width: 550px;
  max-width: 600px;
  position: fixed;
  top: 2%;
  left: 50%;
  transform: translateX(-50%);
  min-height: 200px;
  max-height: 90vh;
  background-color: #fff;
  padding: 20px 30px;
  border-radius: 25px;
  overflow: auto;
}
.portal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  overflow: auto;
  left: 0;
}
.section_home {
  width: 100%;
  height: auto;
  position: relative;
}
.image_poster {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.image_poster img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.banner_paster {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.text_home {
  font-family: Helvetica, sans-serif;
  font-size: 25px;
  line-height: normal;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
  /* text-align: center; */
  margin: 30px 80px 0px 80px;
}
.heading_home {
  font-family: Helvetica, sans-serif;
  font-size: 50px;
  line-height: normal;
  font-weight: 700;
  color: #8b0000;
  letter-spacing: 5px;
  text-transform: uppercase;
  /* text-align: center; */
  margin: 150px 80px 0px 80px;
}
.Stem_button {
  font-family: Helvetica, sans-serif;
  font-size: 16px;
  line-height: normal;
  font-weight: 400;
  color: #ebe7db;
  text-transform: capitalize;
  background-color: #f1a41d;
  border: none;
  height: 47px;
  width: 200px;
  margin: 50px auto 70px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  transition: all 0.4s linear;
  cursor: pointer;
  border: 1px solid #f1a41d;
}
.Stem_button:hover {
  color: blue;
}
.button1_home {
  font-family: Helvetica, sans-serif;
  font-size: 16px;
  line-height: normal;
  font-weight: 400;
  color: #ebe7db;
  text-transform: capitalize;
  background-color: #8b0000;
  border: none;
  height: 47px;
  min-width: 280px;
  margin: 50px auto 70px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  transition: all 0.4s linear;
}
.button1_home:hover {
  background-color: #13336e;
  color: #e9ac99;
}
.button_home {
  font-family: Helvetica, sans-serif;
  font-size: 16px;
  line-height: normal;
  font-weight: 400;
  color: #ebe7db;
  text-transform: capitalize;
  background-color: #8b0000;
  border: none;
  height: 47px;
  min-width: 240px;
  margin: 100px 80px 0px 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  transition: all 0.4s linear;
}
.button_home:hover {
  background-color: #13336e;
  color: #e9ac99;
}
.opportunity_blocks {
  margin-top: 0px;
  width: 100%;
  min-height: 627px;
  background-color: #dbdbdb;
}
.blocks_heading {
  font-family: "Anton", sans-serif;
  font-size: 56px;
  line-height: normal;
  font-weight: 900;
  letter-spacing: 5.6px;
  text-transform: uppercase;
  color: #8b0000;
  text-align: center;
  padding-top: 60px;
}
.Stem_heading {
  font-family: "Anton", sans-serif;
  font-size: 56px;
  line-height: normal;
  font-weight: 800;
  letter-spacing: 5.6px;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
  padding-top: 60px;
}
.Stem1_heading {
  font-family: "Anton", sans-serif;
  font-size: 39px;
  line-height: normal;
  font-weight: 800;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
  padding-top: 60px;
}
.Stem_heading3 {
  font-family: "Anton", sans-serif;
  font-size: 50px;
  line-height: normal;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
  padding-top: 60px;
  color: #8b0000;
}
.Stem_text {
  font-size: 20px;
  line-height: 35px;
  font-weight: 700;
  font-family: Helvetica, sans-serif;
  width: 70%;
  margin: 0 auto;
  text-align: center;
  padding-top: 25px;
  color: #fff;
}
.paragraphs_text {
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  font-family: Helvetica, sans-serif;
  width: 80%;
  margin: 0 auto;
  text-align: justify;
  padding-top: 25px;
}
.Stem_program {
  width: 100%;
  height: auto;
}
.Stem1_program {
  width: 100%;
  height: auto;
}
.Stem2_program {
  width: 100%;
  height: auto;
}
.Stem3_program {
  width: 100%;
  height: auto;
}
.Stem_program_box {
  height: 450px;
  width: 100%;
  background-color: #8b0000;
}
.Stem_program3_box {
  height: 450px;
  width: 100%;
  background-color: #fff;
}
.Socail_icons {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 15px;
}
.Socail_icon {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(1);
  transition: all 0.4s linear;
  color: #8b0000;
}
.Socail_icon:hover {
  transform: scale(1.1);
  color: #13336e;
  background-color: #f1a41d;
}
.Stem_email_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  row-gap: 15px;
  margin: 50px auto;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
}
.stem_phone {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
}
.stem_email {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
}
.stem_phone_text {
  font-size: 18px;
  line-height: normal;
}
.Stem_Sparrow {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.stemImages_box {
  width: 100%;
  height: 100%;
}
.stemImages_box img {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
}

@media screen and (max-width: 1050px) {
  .text_home {
    font-size: 20px;
    margin: 15px 30px 0px 30px;
  }
  .heading_home {
    font-size: 35px;
    margin: 50px 30px 0px 30px;
  }
  .button_home {
    font-size: 14px;
    height: 40px;
    min-width: 220px;
    margin: 50px 30px 0px 30px;
  }
  .Stem_program_box {
    height: 550px;
    width: 100%;
    background-color: #8b0000;
  }
  .Stem_program3_box {
    height: 550px;
    width: 100%;
    background-color: #fff;
  }
  
}
@media screen and (max-width: 700px) {

  .model_style{
    min-width: 95%;
    max-width: 98%;
    position: fixed;
    top: 2%;
    left: 50%;
    transform: translateX(-50%);
    min-height: 200px;
    max-height: 90vh;
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 25px;
    overflow: auto;
  }
  .text_home {
    font-size: 17px;
    margin: 15px 20px 0px 20px;
  }
  .heading_home {
    font-size: 28px;
    margin: 20px 20px 0px 20px;
  }
  .button_home {
    font-size: 12px;
    height: 35px;
    min-width: 200px;
    margin: 30px 20px 0px 20px;
  }
  
}
@media screen and (max-width: 576px) {
  .text_home {
    font-size: 10px;
    margin: 8px 10px 0px 10px;
  }
  .heading_home {
    font-size: 20px;
    margin: 10px 10px 0px 10px;
  }
  .button_home {
    font-size: 10px;
    height: 30px;
    min-width: 150px;
    margin: 12px 10px 0px 10px;
  }
  .Stem_button {
    font-family: Helvetica, sans-serif;
    font-size: 14px;
    line-height: normal;
    font-weight: 400;
    color: #ebe7db;
    text-transform: capitalize;
    background-color: #f1a41d;
    border: none;
    height: 47px;
    width: 200px;
    margin: 50px auto 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    transition: all 0.4s linear;
    cursor: pointer;
    border: 1px solid #f1a41d;
  }
  .Stem_button:hover {
    color: blue;
  }
  .Stem_program3_box {
     padding: 30px 30px 30px 30px;
    height: fit-content;
    width: 100%;
    background-color: #fff;
  }
  .button1_home {
    font-family: Helvetica, sans-serif;
    font-size: 14px;
    line-height: normal;
    font-weight: 400;
    color: #ebe7db;
    text-transform: capitalize;
    background-color: #8b0000;
    border: none;
    height: 47px;
    min-width: 280px;
    margin: 50px auto 70px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    transition: all 0.4s linear;
  }
  .button1_home:hover {
    background-color: #13336e;
    color: #e9ac99;
  }
  .blocks_heading {
    font-family: "Anton", sans-serif;
    font-size: 40px;
    line-height: normal;
    font-weight: 900;
    letter-spacing: 5.6px;
    text-transform: uppercase;
    color: #8b0000;
    text-align: center;
    padding-top: 30px;
  }
  .Stem_heading {
    font-family: "Anton", sans-serif;
    font-size: 40px;
    line-height: normal;
    font-weight: 800;
    letter-spacing: 5.6px;
    text-transform: uppercase;
    color: #ffffff;
    text-align: center;
    padding-top: 0px;
  }
  .Stem1_heading {
    font-family: "Anton", sans-serif;
    font-size: 28px;
    line-height: normal;
    font-weight: 800;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #ffffff;
    text-align: center;
    padding-top: 0px;
  }
  .Stem_heading3 {
    font-family: "Anton", sans-serif;
    font-size: 35px;
    line-height: normal;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #ffffff;
    text-align: center;
    padding-top: 0px;
    color: #8b0000;
  }
  .Stem_text {
    font-size: 17px;
    line-height: 35px;
    font-weight: 700;
    font-family: Helvetica, sans-serif;
    width: 70%;
    margin: 0 auto;
    text-align: center;
    padding-top: 15px;
    color: #fff;
  }
  .paragraphs_text {
    font-size: 17px;
    line-height: 30px;
    font-weight: 400;
    font-family: Helvetica, sans-serif;
    width: 80%;
    margin: 0 auto;
    text-align: justify;
    padding-top: 25px;
  }
  .Stem_program {
    width: 100%;
    height: auto;
  }
  .Stem1_program {
    width: 100%;
    height: auto;
  }
  .Stem2_program {
    width: 100%;
    height: auto;
  }
  .Stem3_program {
    width: 100%;
    height: auto;
  }
  .Stem_program_box {
    height: fit-content;
    padding: 30px 30px 30px 30px;
    width: 100%;
    background-color: #8b0000;
  }

  .Stem_email_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    row-gap: 15px;
    padding: 50px 20px;
    margin: 0 auto;
    font-size: 15px;
    color: #fff;
    cursor: pointer;
  }

  .stem_phone_text {
    font-size: 15px;
    line-height: normal;
  }
  .Stem_email_box {

    margin: 50px auto 0px auto;
    font-size: 18px;
    padding: 0px;
 
  }
}
