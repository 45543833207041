@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&display=swap");

.Stem1_program {
  width: 100%;
  height: auto;
}
.Stem2_program {
  width: 100%;
  min-height: 300px;
  background-color: #8b0000;
  display: flex;
  align-items: center;
  padding: 0px 15%;
}
.model_overlay{
  background: linear-gradient(rgba(127, 127, 127,.5) 0%, rgba(127, 127, 127,.5) 100%) !important;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
}
.model_body{
  margin-top: 10px;
  min-height: 120px !important;

}
.model_style{
  min-width: 550px;
  max-width: 600px;
  position: fixed;
  top: 2%;
  left: 50%;
  transform: translateX(-50%);
  min-height: 200px;
  max-height: 90vh;
  background-color: #fff;
  padding: 20px 30px;
  border-radius: 25px;
  overflow: auto;
  text-align: justify;
}
.portal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  overflow: auto;
}
.stemImages_box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.new_box {
  height: 85%;
  width: 85%;
}
.stemImages_box img {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
}
.Stem_program_box {
  min-height: 550px;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 20px;
}
.Stem_heading {
  font-family: "Anton", sans-serif;
  font-size: 56px;
  line-height: normal;
  font-weight: 800;
  letter-spacing: 5.6px;
  text-transform: uppercase;
  color: #8b0000;
  text-align: center;
}
.stem_heading3 {
  font-family: "Anton", sans-serif;
  font-size: 56px;
  line-height: normal;
  font-weight: 800;
  letter-spacing: 5.6px;
  text-transform: uppercase;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}
.Stem_text {
  font-size: 20px;
  line-height: 35px;
  font-weight: 400;
  font-family: Helvetica, sans-serif;
  width: 90%;
  margin: 0 auto;
  text-align: center;
  padding-top: 25px;
  color: #000;
}
.Stem_button {
  font-family: Helvetica, sans-serif;
  font-size: 16px;
  line-height: normal;
  font-weight: 400;
  color: #ebe7db;
  text-transform: capitalize;
  background-color: #8b0000;
  border: none;
  height: 47px;
  width: 200px;
  margin: 50px auto 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  transition: all 0.4s linear;
  cursor: pointer;
}
.Stem_button:hover {
  background-color: #13336e;
  color: #e9ac99;
}
.Store_container {
  width: 100%;
  background-color: #dbdbdb;
  padding: 60px 40px 40px 40px;
}
.store_boxes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  gap: 20px;
  width: 90%;
  margin: 40px auto;
}
.store_box_item {
  width: auto;
  height: 460px;
  border-radius: 15px;
  border: 1px solid #fff;
}
.store_box_image {
  height: 75%;
  width: 100%;
  border-radius: 15px 15px 0px 0px;
  overflow: hidden;
}
.store_box_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px 15px 0px 0px;
  transform: scale(1);
  transition: transform 0.4s linear;
}
.store_box_image img:hover {
  transform: scale(1.1);
}
.store_box_title {
  height: 25%;
  width: 100%;
  background-color: #fff;
  border-radius: 0px 0px 15px 15px;
  padding: 0px 10px ;
  display: flex;
  justify-content: center;
  align-items: center;
}
.store_boxtext {
  font-family: Helvetica, sans-serif;
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
  color: #8b0000;
  text-align: center;
}
.stem_second_side{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    row-gap: 20px;
}
.stem_second_text{
    font-size: 16px;
    font-weight: 400px;
    color: #fff;
    line-height: 28px;
}
.stem_second_line{
    height: 2px;
    background-color: #fff;
    width: 10%;
}
@media (max-width:1024px) {
  .store_boxes{
    display: grid;
    grid-template-columns: repeat(2,50%);
    grid-template-rows: auto;
    width: 95%;
  }
}
@media (max-width:768px) {
  .Stem_heading{
    font-size: 48px;
    line-height: 60px;
  }
  .store_boxes{
    width: 100%;
  }
  .store_boxtext{
    font-size: 16px;
    line-height: 24px;
    padding: 0 5px;
  }
  .Store_container{
    padding: 60px 40px 40px 20px;
  }
  .Stem_button{
    margin: 20px auto 0 auto;
  }
  .Stem2_program{
    padding: 0 5%;
  }


  .model_style{
    min-width: 95%;
    max-width: 98%;
    position: fixed;
    top: 2%;
    left: 50%;
    transform: translateX(-50%);
    min-height: 200px;
    max-height: 90vh;
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 25px;
    overflow: auto;
  }
}
@media (max-width:600px) {
  .Stem_program_box{
    min-height: fit-content;
    padding: 0px 20px 20px 20px ;
  }
  .Stem_heading{
    font-size: 30px;
    line-height: 50px;
  }
  .Stem_text{
    font-size: 18px;
    line-height: 32px;
  }
  .store_boxes{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
  .Store_container {
    padding: 30px 30px 30px 30px;
  }
  .store_box_item{
    height: 330px;
  }
  .Stem2_program{
    height: 330px;
    padding: 10px 20px;
  }
  .stem_heading3{
    font-size: 38px;
    line-height: 50px
  }
  .CTA .Stem_program_box{
    height: 300px;
  }
}
