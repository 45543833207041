@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&display=swap");

/*  Mr Asad Class */
.portal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  overflow: auto;
  left: 0;
}

.contactus_section {
  width: 100% !important;
  height: auto;
}

.img_row {
  width: 100%;
  height: 100%;
  position: relative;
}

.img_row img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.white_text_box {
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
  min-width: 300px;
  max-width: 350px;
  height: auto;
  padding: 40px;
  background-color: #fff;
}

.Stem_heading {
  font-family: "Anton", sans-serif;
  font-size: 56px;
  line-height: normal;
  font-weight: 800;
  letter-spacing: 5.6px;
  text-transform: uppercase;
  color: #8b0000;
  text-align: center;
}

.container_details {
  width: 100%;
  padding: 3% 6%;
  margin: 0 auto;
}

.address_details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 0px;
}

.address {
  font-family: "Anton", sans-serif;
  line-height: normal;
  font-weight: 400;
  font-size: 40px;
  color: #8b0000;
  margin-bottom: 20px;
}

.icons {
  display: flex;
  column-gap: 20px;
  font-size: 20px;
  margin-top: 30px;
  color: #8b0000;
}

.padding_ajy {
  font-family: Helvetica, sans-serif;
  font-size: 16px;
  line-height: 30px;
  font-weight: 400px;
}

.download_box {
  width: 100%;
  min-height: 413px;
  background: #dbdbdb;
  display: grid;
  place-items: center;
}

.download_details {
  text-align: center;
  min-width: 80%;
}

.brochure {
  color: #8b0000;
  font-family: "Anton", sans-serif;
  line-height: normal;
  font-size: 56px;
  font-weight: 700;
  letter-spacing: 3px;
}

.brochure_text {
  font-family: Helvetica, sans-serif;
  font-size: 16px;
  line-height: normal;
  font-weight: bold;
  color: #000000;
  margin: 35px 15px;
}

.brochure_button {
  width: 142px;
  height: 47px;
  font-family: Helvetica, sans-serif;
  font-size: 16px;
  background-color: #f1a41d;
  border-radius: 12px;
  color: #ffff;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  transition: all 0.4s linear;
}

.disableButton {
  width: 142px;
  height: 47px;
  font-family: Helvetica, sans-serif;
  font-size: 16px;
  background-color: gray !important;
  border-radius: 12px;
  cursor: not-allowed;
  color: #ffff;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  transition: all 0.4s linear;
}

.brochure_button:hover {
  /* background-color: white; */
  color: white;
  /* border: 1px solid red; */
}

.map_box {
  height: 440px;
  width: 100%;
  padding: 20px 50px;
}

.map_box iframe {
  width: 100% !important;
  height: 100% !important;
}

.form_container {
  width: 100%;
  min-height: 550px;
  padding: 30px 0px;
  display: flex;
  align-items: center;
  row-gap: 40px;
  background-color: #8b0000;
}

.form_main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  padding: 0px 20px 0px 20px;
}

.top2_input {
  display: flex;
}

.text_input {
  width: 100%;
  height: 37px;
}

.label_name {
  font-size: 14px;
  color: #ffff;
  display: block;
}

.email_input {
  width: 417px !important;
  height: 37px !important;
}

.message {
  width: 417px;
  resize: none;
  height: 66px !important;
}

.contact_errors {
  color: #f1a41d;
  padding: 8px 0px 0px 0px;
  margin-bottom: 0px;
  font-size: 14px;
  line-height: normal;
}

@media screen and (max-width: 768px) {
  .white_text_box {
    min-width: 250px;
    max-width: 280px;
    padding: 20px;
  }

  .Stem_heading {
    font-size: 40px;
    letter-spacing: 4.5px;
  }

  .brochure {
    color: #8b0000;
    font-family: "Anton", sans-serif;
    line-height: normal;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 3px;
    padding: 0px 20px;
  }

  .brochure_text {
    font-family: Helvetica, sans-serif;
    font-size: 16px;
    line-height: 30px;
    font-weight: bold;
    color: #000000;
    margin: 35px 15px;
  }

  .form_main {
    padding: 0px 20px 0px 20px;
  }

  .map_box {
    padding: 20px 20px;
  }
}
@media screen and (max-width: 600px) {
  .white_text_box {
    min-width: 160px;
    max-width: 160px;
    padding: 25px;
  }

  .Stem_heading {
    font-size: 20px;
    font-weight: 500;
  }
}
