
body {
  margin: 0;
  font-family: Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.app-loader {
  background-color: #8b0000 !important;
  min-height: 100vh;
  width: 100%;
  margin: auto 0px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.my-loading-text{
  font-family: Helvetica, sans-serif;
  color: #fff !important;
}