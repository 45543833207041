@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
* {
  box-sizing: border-box;
}
.main {
  /* border: 1px solid red; */
  width: 100%;
  min-height: 100vh;
}
.topContentBox {
  width: 100%;
  min-height: 550px;
  padding: 40px 20px;
  flex-direction: column;
  background-color: #ffffff;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid gold; */
}
.topHeading {
  font-family: "Anton", sans-serif;
  font-size: 56px;
  letter-spacing: 5.6px;
  /* border: 2px solid green; */
  margin: 0px auto 30px auto;
  text-align: center;
  color: #8b0000;
  font-weight: 700;
}
.topBoxPara {
  /* border: 2px solid red; */
  width: 70%;
  margin: 15px auto;
  font-family: Helvetica, sans-serif;
  line-height: 30px;
  font-size: 20px;
  line-height: 25px;
  text-align: justify;
}
.topBoxParaTwo {
  /* border: 2px solid red; */
  width: 70%;
  margin: 25px auto;
  font-family: Helvetica, sans-serif;
  line-height: 30px;
  font-size: 20px;
  line-height: 25px;
  text-align: justify;
}
.topBoxParathree {
  /* border: 2px solid red; */
  width: 70%;
  margin: 25px auto;
  font-family: Helvetica, sans-serif;
  line-height: 30px;
  font-size: 20px;
  line-height: 25px;
  text-align: justify;
}
@keyframes floating {
  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, 15px); }
  100%   { transform: translate(0, -0px); }    
}
.imageContentBox {
  animation-name: floating;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left:0px;
    margin-top: 0px;
    z-index: -1;
  background:linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.4)), url("../../../assets/About/imageBox.jpg");
  min-height: 600px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.imagePara {
  min-height: 350px;
  width: 47%;
  color: white;
  /* background: black; */
  opacity: 0.5;
  box-sizing: border-box;
  padding: 10px;
  font-size: 22px;
  line-height: 39px;
  text-align: justify;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  position: absolute;
  top: 50%;
  left: 5%;
  transform: translateY(-50%);
}

.career_box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center !important;
  min-height: 391px;
  background-color: #dbdbdb;
  padding: 40px;
}
.heading {
  color: #8b0000;
  font-family: "Anton", sans-serif;
  line-height: normal;
  font-size: 56px;
  font-weight: 800;
  letter-spacing: 3px;
  padding-right: 40px;
}
.ocmt_tex {
  font-family: Helvetica, sans-serif;
  line-height: 30px;
  font-size: 18px;
  color: #000000;
  font-style: bold;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
}
.Stem2_program {
  width: 100%;
  min-height: auto;
  overflow: hidden;
}
.Stem_program_box {
  height: 100%;
  width: 100%;
  padding: 60px 0px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 50px;
}
.stemImages_box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.new_box {
  height: 85%;
  width: 85%;
}
.stemImages_box img {
  width: 100%;
  height: 100% !important;
}
.content_box {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.Stem_heading {
  font-family: "Anton", sans-serif;
  font-size: 38px;
  line-height: normal;
  font-weight: 500;
  letter-spacing: normal;
  text-transform: capitalize;
  color: #8b0000;
  text-align: center;
}
.Stem_text {
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  font-family: Helvetica, sans-serif;
  width: 90%;
  margin: 0 auto;
  text-align: center;
  color: #000;
  text-align: justify;
}

@media screen and (max-width: 900px) {
  .imagePara {
    min-height: 350px;
    width: 60%;
    color: white;
    font-size: 22px;
    line-height: 39px;
    text-align: justify;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translateY(-50%);
  }
}
@media screen and (max-width: 768px) {
  .topHeading {
    font-family: "Anton", sans-serif;
    font-size: 45px;
  }
  .topBoxPara {
    /* border: 2px solid red; */
    width: 90%;
    margin: 15px auto;
    font-size: 17px;
  }
  .topBoxParaTwo {
    /* border: 2px solid red; */
    width: 90%;
    margin: 25px auto;
    font-size: 17px;
  }
  .topBoxParathree {
    /* border: 2px solid red; */
    width: 90%;
    margin: 25px auto;
    font-size: 17px;
  }
  .imagePara {
    min-height: 350px;
    width: 75%;
    color:white;
    font-size: 19px;
    line-height: 30px;
    text-align: justify;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translateY(-50%);
  }
  .heading {
    font-size: 45px;
    padding-right: 20px;
    text-align: center;
  }
  .ocmt_tex {
    font-size: 16px;
    padding-left: 20px;
    row-gap: 25px;
    text-align: justify;
  }
}
@media screen and (max-width: 576px) {
  .topHeading {
    font-family: "Anton", sans-serif;
    font-size: 40px;
  }
  .imagePara {
    min-height: 350px;
    width: 88%;
    color: white;
    font-size: 18px;
    line-height: 25px;
    text-align: justify;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translateY(-50%);
  }
  .heading {
    font-size: 40px;
    padding-right: 0px;
    text-align: center;
    padding-bottom: 20px;
  }
  .ocmt_tex {
    font-size: 15px;
    padding-left: 20px;
    row-gap: 25px;
    text-align: justify;
  }
}
